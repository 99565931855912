<template>
  <div>
    <div>
      <el-image fit="contain" :src="abouts" />
    </div>
    <el-breadcrumb separator="/" style="margin-left:10px; margin-top:10px">
      <el-breadcrumb-item :to="{ path: '/' }">
        {{ $t('Nav.home') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item> {{ $t('Nav.cpfa') }} </el-breadcrumb-item>
    </el-breadcrumb>
    <div style="position: relative;clear: both; margin-top:20px">
      <div style="width:100%; margin:0 auto; background:#F6F7F8;">
        <div style="margin-top:20px">
          <div
            style="text-align:center; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_1') }}
          </div>
          <div
            style="text-align:left; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height:70px;"
          >
            {{ $t('Nav.case04_2') }}
          </div>
          <div
            style="text-indent:30px;font-size: 14px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.case04_4') }}
          </div>
          <div
            style="text-align:left; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_3') }}
          </div>
          <div
            style="text-indent:30px;font-size: 14px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.case04_5') }}
          </div>
        </div>
        <div style="margin-top:20px;">
          <div
            style="text-align:left; height: 24px;  font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_6') }}
          </div>
          <img :src="qygsImg" style="width:100%;margin-top:20px" alt="" />
        </div>
      </div>
      <div style="width:100%; margin:0 auto; ">
        <div style="margin-top:20px">
          <div
            style="text-align:left; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_7') }} - {{ $t('Nav.case04_8') }}
          </div>
          <div
            style="text-indent:30px;font-size: 14px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.case04_9') }}
          </div>
          <div
            style="text-align:left; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_10') }}
          </div>
          <div
            style="text-indent:30px;font-size: 14px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.case04_11') }}
          </div>
          <div
            style="text-align:left; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_12') }}
          </div>
          <div
            style="text-indent:30px;font-size: 14px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.case04_13') }}
          </div>
          <div
            style="text-align:left; height: 24px; padding-top:20px; font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.case04_14') }}
          </div>
          <div
            style="text-indent:30px;font-size: 14px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.case04_15') }}
          </div>
        </div>
        <div style="margin-top:20px;">
          <img :src="qygsImg2" style="width:100%;" alt="" />
        </div>
      </div>
    </div>
    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'itc',
  components: {
    // HelloWorld
  },
  data() {
    return {
      abouts: require('../../assets/index/03.jpg'),
      ict: require('../../assets/mobile/07-img@3x.png'),
      point: require('../../assets/point.gif'),
      itc1: require('../../assets/itc/1.jpg'),
      itc2: require('../../assets/itc/5.jpg'),
      itc3: require('../../assets/itc/anli.png'),
      qygsImg: require('../../assets/chanpin/case04/2.png'),
      qygsImg2: require('../../assets/chanpin/case04/01.jpeg'),
    }
  },
  methods: {},
}
</script>

<style></style>
